import { CreateUserSchema } from 'typings/user';
import {
    APIInvitationResponse,
    CreateTargetGroupSchema,
    GroupType,
    TargetGroupParticipant,
    TargetGroupUpdate,
    UserInvitation,
} from 'typings/target_group';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { CreateDashboardUserSchema } from 'typings/dashboard_user';
import {
    Assessment,
    AssessmentCreationConfig,
    AssessmentUpdate,
} from '../typings/assessment';
import { NEUROLYTICS_API } from 'utils/environment-variables';
import { auth0Client } from 'Auth';
import { Company } from '../store/companies/companies.reducer';
import { MediaFile } from '../typings/mediaFiles';

const getAuthToken = async () => {
    if (auth0Client) {
        try {
            return await auth0Client.getTokenSilently();
        } catch (error) {
            throw error;
        }
    } else {
        return null;
    }
};

const axiosConfig = async (
    endpoint: string,
    method: string,
    data: object,
    contentType: string,
    isPublic: boolean,
) => {
    const isNeurolyticsRequest = !endpoint.startsWith('http');
    try {
        let headers: {
            'Content-Type': string;
            Authorization?: string;
        } = {
            'Content-Type': contentType,
        };
        if (!isPublic) {
            const accessToken = await getAuthToken();
            if (accessToken) {
                headers = {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`,
                };
            }
        }
        return {
            method,
            url: isNeurolyticsRequest
                ? `${NEUROLYTICS_API}/${endpoint}`
                : endpoint,
            headers,
            data,
        };
    } catch (error) {
        throw error;
    }
};

export const apiRequest = async (
    endpoint: any,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET',
    data: object = {},
    contentType: string = 'application/json',
    isPublic: boolean = false,
    returnRawResponse: boolean = false,
) => {
    const config: object = await axiosConfig(
        endpoint,
        method,
        data,
        contentType,
        isPublic,
    );
    const axiosResponse = await axios(config);
    return returnRawResponse ? axiosResponse : axiosResponse.data;
};

export class NeurolyticsAPI {
    async fetchCompanyAssessments(
        companyId: string,
        include_modules: boolean,
    ): Promise<Assessment[]> {
        try {
            const response = await apiRequest(
                `companies/${companyId}/assessments?include_modules=${include_modules}`,
                'GET',
                {},
                'application/json',
                false,
            );
            return response.data ? response.data : [];
        } catch (error) {
            const errorMessage = `fetchCompanyAssessments API Request failed using companyId: ${companyId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getAssessmentApi(companyId: string, assessmentId: string): Promise<Assessment> {
        try {
            return await apiRequest(
                `companies/${companyId}/assessments/${assessmentId}`,
                'GET',
                {},
                'application/json',
                false,
            );
        } catch (error) {
            const errorMessage = `getAssessment API Request failed using companyId: ${companyId} and assessmentId: ${assessmentId}. ${
                error instanceof Error && error.message
                    ? error.message
                    : 'UnExpectedError'
            }.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async updateAssessmentConfig(companyId: string, assessmentId: string, data: AssessmentUpdate) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/assessments/${assessmentId}/config`,
                'PUT',
                data,
                'application/json',
                false,
            );
            return {
                assessment: response,
            };
        } catch (error) {
            const errorMessage = `updateAssessmentConfig API Request failed using companyId: ${companyId} and assessmentId: ${assessmentId} and data: ${data}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async requestCreateAssessment(
        companyId: string,
        assessment: AssessmentCreationConfig,
    ): Promise<Assessment> {
        try {
            return await apiRequest(
                `companies/${companyId}/assessments`,
                'POST',
                assessment,
                'application/json',
                false,
            );
        } catch (error) {
            const errorMessage = `requestCreateAssessment API Request failed using companyId: ${companyId}. ${
                error instanceof Error && error.message
                    ? error.message
                    : 'UnExpectedError'
            }.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    private async getS3PreSignedUrl( //todo
        companyId: string,
        params: {
            type: string;
            file_name: string;
            params?: { interview_video_number?: number };
        },
    ): Promise<{ url: string; id: string }> {
        return apiRequest(
            `${companyId}/upload`,
            'POST',
            params,
            'application/json',
            false,
        );
    }

    async uploadVideoForScan( //todo
        companyId: string,
        params: {
            type: string;
            file_name: string;
            params?: { interview_video_number?: number };
        },
        file: File,
    ): Promise<{
        url: string;
        id: string;
    }> {
        try {
            const presignedUrl = await this.getS3PreSignedUrl(
                companyId,
                params,
            );
            await apiRequest(presignedUrl.url, 'PUT', file, 'video/mp4', true);
            await apiRequest(`media-file/${presignedUrl.id}`, 'PATCH');
            return {
                url: presignedUrl.url.split('?')[0],
                id: presignedUrl.id,
            };
        } catch (error) {
            const errorMessage = `requestUpload API Request failed when trying to upload: ${params}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getCompanyGroupsRequest(companyId: string, type: GroupType) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/groups?type=${type}`,
                'GET',
                {},
                'application/json',
                false,
            );
            return response.data ? response.data : [];
        } catch (error) {
            const errorMessage = `getCompanyGroups API Request failed using companyId: ${companyId}, groups type: ${type}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getAssessmentTargetGroups(assessmentId: string) { //todo
        try {
            const response = await apiRequest(
                `assessments/${assessmentId}/target_groups`,
                'GET',
                {},
                'application/json',
                false,
            );
            return response.data ? response.data : [];
        } catch (error) {
            const errorMessage = `getAssessmentTargetGroups API Request failed using assessmentId: ${assessmentId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getTargetGroupById(companyId: string, targetGroupId: string) {
        try {
            return await apiRequest(
                `companies/${companyId}/target_groups/${targetGroupId}`,
                'GET',
                {},
                'application/json',
                false,
            );
        } catch (error) {
            const errorMessage = `getTargetGroupById API Request failed using companyId: ${companyId} and targetGroupId: ${targetGroupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getAllInvitationsInGroupApi(targetGroupId: string) {
        try {
            const response = await apiRequest(
                `target_groups/${targetGroupId}/invitations`,
                'GET',
                {},
                'application/json',
                false,
            );
            const invitations: UserInvitation[] = response?.map(
                (invitation: APIInvitationResponse) => ({
                    user_id: invitation.user_id,
                    invitation_created_at: invitation.created_at,
                    status: invitation.status ? invitation.status : {},
                }),
            );
            return invitations;
        } catch (error) {
            const errorMessage = `getAllInvitationsInGroup API Request failed using groupId: ${targetGroupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getWorkPreferenceBaseline(
        assessmentId: string,
        targetGroupId: string,
    ) {
        try {
            return await apiRequest(
                `assessments/${assessmentId}/target_groups/${targetGroupId}/work-preference-baseline`,
                'GET',
                {},
                'application/json',
                false,
            );
        } catch (error) {
            const errorMessage = `getWorkPreferenceBaseline API Request failed using assessmentId: ${assessmentId} and targetGroupId: ${targetGroupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getTargetGroupUsers(assessmentId: string, targetGroupId: string) {
        try {
            const response = await apiRequest(
                `assessments/${assessmentId}/target_groups/${targetGroupId}/participants`,
                'GET',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `getTargetGroupUsers API Request failed using assessmentId: ${assessmentId} and targetGroupId: ${targetGroupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async deleteEmptyTargetGroup(assessmentId: string, targetGroupId: string) {
        try {
            return await apiRequest(
                `assessments/${assessmentId}/target_groups/${targetGroupId}`,
                'DELETE',
            );
        } catch (error) {
            const errorMessage = `deleteEmptyTargetGroupById API Request failed using assessmentId: ${assessmentId} and targetGroupId: ${targetGroupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async deleteEmptyScan(assessmentId: string) {
        try {
            return await apiRequest(`assessments/${assessmentId}`, 'DELETE');
        } catch (error) {
            const errorMessage = `deleteEmptyScan API Request failed using assessmentId: ${assessmentId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async fetchCandidate(candidateId: string) {
        try {
            const response = await apiRequest(
                `users/${candidateId}`,
                'GET',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `fetchCandidate API Request failed using candidateId: ${candidateId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getUserActivityApi(
        targetGroupId: string,
        userId: string,
        includeReportUrl: boolean,
    ) {
        try {
            const response = await apiRequest(
                `target_groups/${targetGroupId}/users/${userId}/activity?include_report_url=${includeReportUrl}`,
                'GET',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `getUserActivity API Request failed using targetGroupId: ${targetGroupId} and userId: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getUserEmailInfo(targetGroupId: string, userId: string) {
        try {
            const response = await apiRequest(
                `target_groups/${targetGroupId}/users/${userId}/emails_info`,
                'GET',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `getUserEmailInfo API Request failed using targetGroupId: ${targetGroupId} and userId: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async createDashboardUserApi(
        companyId: string,
        user: CreateDashboardUserSchema,
    ) {
        try {
            const response = await apiRequest(
                `users/recruiters/${companyId}`,
                'POST',
                user,
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `createUsers API Request failed with user: ${user}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getAllDashboardUsersApi(companyId: string) {
        try {
            const response = await apiRequest(
                `users/recruiters/${companyId}`,
                'GET',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `getAllDashboardUsers API Request failed with company id: ${companyId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async deleteRecruiterUsersApi(companyId: string, userIds: string[]) {
        try {
            const response = await apiRequest(
                `users/recruiters/${companyId}`,
                'DELETE',
                { user_ids: userIds },
                'application/json',
                false,
            );
            return {
                response,
            };
        } catch (error) {
            const errorMessage = `deleteRecruiterUsersApi API Request failed using companyId: ${companyId} and users: ${userIds}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async addParticipantsToTargetGroup(
        assessmentId: string,
        targetGroupId: string,
        participants: CreateUserSchema[],
    ) {
        try {
            const response = await apiRequest(
                `assessments/${assessmentId}/target_groups/${targetGroupId}/add_participants`,
                'PUT',
                { participants: JSON.stringify(participants) },
                'application/json',
                false,
            );
            return {
                targetGroup: response,
            };
        } catch (error) {
            const errorMessage = `addParticipantsToTargetGroup API Request failed using assessmentId: ${assessmentId}, targetGroupId: ${targetGroupId} and participants: ${participants}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async removeParticipantsFromTargetGroup(
        assessmentId: string,
        targetGroupId: string,
        participants: TargetGroupParticipant[],
    ) {
        try {
            const response = await apiRequest(
                `assessments/${assessmentId}/target_groups/${targetGroupId}/remove_participants`,
                'DELETE',
                { participants: JSON.stringify(participants) },
                'application/json',
                false,
            );
            return {
                targetGroup: response,
            };
        } catch (error) {
            const errorMessage = `removeParticipantsFromTargetGroup API Request failed using assessmentId: ${assessmentId}, targetGroupId: ${targetGroupId} and participants: ${participants}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async fullDeleteParticipantsFromTargetGroup(
        assessmentId: string,
        targetGroupId: string,
        participants: TargetGroupParticipant[],
    ) {
        try {
            const response = await apiRequest(
                `assessments/${assessmentId}/target_groups/${targetGroupId}/remove_scanned_participants`,
                'DELETE',
                { participants: JSON.stringify(participants) },
                'application/json',
                false,
            );
            return {
                targetGroup: response,
            };
        } catch (error) {
            const errorMessage = `fullDeleteParticipantsFromTargetGroup API Request failed using assessmentId: ${assessmentId}, targetGroupId: ${targetGroupId} and participants: ${participants}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async sendResetPasswordEmails(userId: string) {
        try {
            return await apiRequest(
                `send_change_password`,
                'POST',
                { users_ids: JSON.stringify([userId]) },
                'application/json',
                false,
            );
        } catch (error) {
            const errorMessage = `sendResetPasswordEmail API Request failed using user ids: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async sendReminderEmails(
        assessmentId: string,
        targetGroupId: string,
        userId: string,
    ) {
        try {
            const response = await apiRequest(
                `assessments/${assessmentId}/target_groups/${targetGroupId}/send_reminder`,
                'POST',
                { users_ids: JSON.stringify([userId]) },
                'application/json',
                false,
            );
            return {
                targetGroup: response,
            };
        } catch (error) {
            const errorMessage = `sendReminderEmails API Request failed using assessmentId: ${assessmentId}, targetGroupId: ${targetGroupId} and userId: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async sendReportEmails(
        assessmentId: string,
        targetGroupId: string,
        userId: string,
    ) {
        try {
            const response = await apiRequest(
                `assessments/${assessmentId}/target_groups/${targetGroupId}/send_report`,
                'POST',
                { users_ids: JSON.stringify([userId]) },
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `sendReportEmails API Request failed using assessmentId: ${assessmentId}, targetGroupId: ${targetGroupId} and userId: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async resetCandidateScan(
        companyId: string,
        targetGroupId: string,
        userId: string,
    ) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/groups/${targetGroupId}/participants/${userId}`,
                'PATCH',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `resetCandidateScan API Request failed using companyId: ${companyId}, targetGroupId: ${targetGroupId} and userId: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async convertUserVideos(targetGroupId: string, userId: string) {
        try {
            const response = await apiRequest(
                `target_groups/${targetGroupId}/users/${userId}/convert_videos`,
                'PUT',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `convertUserVideos API Request failed using targetGroupId: ${targetGroupId} and userId: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async updateTargetGroupConfig(
        assessmentId: string,
        targetGroupId: string,
        data: TargetGroupUpdate,
    ) {
        try {
            const response = await apiRequest(
                `assessments/${assessmentId}/target_groups/${targetGroupId}/config`,
                'PUT',
                data,
                'application/json',
                false,
            );
            return {
                targetGroup: response,
            };
        } catch (error) {
            const errorMessage = `updateTargetGroupConfig API Request failed using assessmentId: ${assessmentId}, targetGroupId: ${targetGroupId} and data: ${data}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async createTargetGroup(
        assessmentId: string,
        data: CreateTargetGroupSchema,
    ) {
        try {
            const response = await apiRequest(
                `assessments/${assessmentId}/target_groups`,
                'POST',
                data,
                'application/json',
                false,
            );
            return {
                targetGroup: response,
            };
        } catch (error) {
            const errorMessage = `createTargetGroup API Request failed using assessmentId: ${assessmentId} and data: ${data}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async createCompany(company: {
        name: string;
        country: string;
        slug: string;
        logoUrl: string;
    }): Promise<Company> {
        try {
            const response = await apiRequest(
                `companies`,
                'POST',
                company,
                'application/json',
                false,
            );
            return response ? response : {};
        } catch (error) {
            const errorMessage = `createCompany API Request failed with company info: ${company}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async uploadWithPresignedUrlFlow(
        params: {
            type: string;
            file_name: string;
            params?: { company_slug?: string };
        },
        file: File,
    ): Promise<string | undefined> {
        try {
            const presignedUrl = await apiRequest(
                `request-dashboard-upload`,
                'POST',
                params,
                'application/json',
                false,
            );
            if (presignedUrl) {
                await apiRequest(
                    presignedUrl.url,
                    'PUT',
                    file,
                    'image/png',
                    true,
                );
                await apiRequest(`media-file/${presignedUrl.id}`, 'PATCH');
                return presignedUrl.url.split('?')[0];
            }
        } catch (error) {
            const errorMessage = `requestUpload API Request failed when trying to upload: ${params}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getCompanies() {
        try {
            const response = await apiRequest('companies', 'GET');
            return response && response.length ? response : [];
        } catch (error) {
            const errorMessage = `getCompanies API Request failed. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getCompanyById(companyId: string) {
        try {
            const response = await apiRequest(`companies/${companyId}`, 'GET');
            return response ? response : {};
        } catch (error) {
            const errorMessage = `getCompanyById API Request failed. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async fetchCompanyMediaFiles(companyId: string): Promise<MediaFile[]> {
        try {
            const response = await apiRequest(
                `company/${companyId}/media-files`,
                'GET',
                { return_in_active: false },
            );
            return response && response.data ? response.data : [];
        } catch (error) {
            const errorMessage = `getMediaFiles API Request failed. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getTargetGroupFeedback(companyId: string, targetGroupId: string) {
        try {
            return await apiRequest(
                `companies/${companyId}/groups/${targetGroupId}/feedback`,
                'GET',
            );
        } catch (error) {
            const errorMessage = `getTargetGroupFeedback API Request failed using companyId= ${companyId} targetGroupId: ${targetGroupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }
}

export default new NeurolyticsAPI();
