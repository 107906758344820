import {AssessmentCreationConfig, AssessmentUpdate} from '../../typings/assessment';
import {CreateTargetGroupSchema} from '../../typings/target_group';
import { NavigateFunction } from 'react-router-dom';

export const actionsTypes = {
    GET_COMPANY_ASSESSMENTS: 'GET_COMPANY_ASSESSMENTS',
    GET_COMPANY_ASSESSMENTS_SUCCESS: 'GET_COMPANY_ASSESSMENTS_SUCCESS',
    GET_COMPANY_ASSESSMENTS_FAILURE: 'GET_COMPANY_ASSESSMENTS_FAILURE',
    GET_ASSESSMENT: 'GET_ASSESSMENT',
    GET_ASSESSMENT_SUCCESS: 'GET_ASSESSMENT_SUCCESS',
    GET_ASSESSMENT_FAILURE: 'GET_ASSESSMENT_FAILURE',
    CREATE_ASSESSMENT: 'CREATE_ASSESSMENT',
    CREATE_ASSESSMENT_SUCCESS: 'CREATE_ASSESSMENT_SUCCESS',
    CREATE_ASSESSMENT_FAILURE: 'CREATE_ASSESSMENT_FAILURE',
    UPDATE_ASSESSMENT_CONFIG: 'UPDATE_ASSESSMENT_CONFIG',
    UPDATE_ASSESSMENT_CONFIG_SUCCESS: 'UPDATE_ASSESSMENT_CONFIG_SUCCESS',
    UPDATE_ASSESSMENT_CONFIG_FAILURE: 'UPDATE_ASSESSMENT_CONFIG_FAILURE',
    DELETE_SCAN: 'DELETE_SCAN',
    DELETE_SCAN_SUCCESS: 'DELETE_SCAN_SUCCESS',
    DELETE_SCAN_FAILURE: 'DELETE_SCAN_FAILURE',
    CREATE_SCAN: 'CREATE_SCAN',
    CREATE_SCAN_SUCCESS: 'CREATE_SCAN_SUCCESS',
    CREATE_SCAN_FAILURE: 'CREATE_SCAN_FAILURE',
};

export const actions = {
    getCompanyAssessments: (
        companyId: string,
        include_modules: boolean = false,
    ) => ({
        type: actionsTypes.GET_COMPANY_ASSESSMENTS,
        payload: { companyId, include_modules },
    }),
    getAssessment: (companyId: string, assessmentId: string) => ({
        type: actionsTypes.GET_ASSESSMENT,
        payload: { companyId, assessmentId },
    }),
    createAssessment: (
        companyId: string,
        assessmentCreationConfig: AssessmentCreationConfig,
        videos?: { cultureFit?: File; speaking1?: File; speaking2?: File },
    ) => ({
        type: actionsTypes.CREATE_ASSESSMENT,
        payload: { companyId, assessmentCreationConfig, videos },
    }),
    updateAssessmentConfig: (companyId: string, assessmentId: string, data: AssessmentUpdate) => ({
        type: actionsTypes.UPDATE_ASSESSMENT_CONFIG,
        payload: { companyId, assessmentId, data }
    }),
    deleteEmptyScan: (
        assessmentId: string,
        navigate: NavigateFunction,
        currentCompanySlug?: string,
    ) => ({
        type: actionsTypes.DELETE_SCAN,
        payload: {
            assessmentId,
            navigate,
            currentCompanySlug,
        },
    }),
    createScan: (
        companyId: string,
        assessmentCreationConfig?: AssessmentCreationConfig,
        createTargetGroupSchema?: CreateTargetGroupSchema,
        existingAssessmentId?: string,
        navigate?: NavigateFunction,
        companySlug?: string,
    ) => ({
        type: actionsTypes.CREATE_SCAN,
        payload: {
            companyId,
            assessmentCreationConfig,
            createTargetGroupSchema,
            existingAssessmentId,
            navigate,
            companySlug,
        },
    }),
};
